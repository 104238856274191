select {
  border: 1px solid #C5C5C6;
  border-radius: 5px;
  color: #676767;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 5px;
  line-height: 1.5;
  background: #fff url("../../svg/chevron.svg") no-repeat right .75rem center;
  background-size: 23px 15px;
  appearance: none;
}

input[type="text"],
input[type="number"] {
  border: 1px solid #C5C5C6;
  border-radius: 5px;
  color: #676767;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 5px;
  line-height: 1.5;
  box-sizing: border-box;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  }
}

input:disabled,
input[readonly] {
  background-color: #e9ecef;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-flow: column nowrap;
}

label {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: #14171A;
  margin-bottom: 4px;
}

textarea {
  border: 1px solid #C5C5C6;
  border-radius: 5px;
  color: #676767;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  padding: 5px;
  line-height: 1.5;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  }
}

input[type="image"] {
  width: 19px;
  height: 24px;
  margin: 0 7px 0 9px;
  justify-self: end;
  align-self: center;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  }
}