.edit-resource {

  @import "../modules/btn";
  @import "../modules/form";

  padding: 12px 0 24px 0;
  display: grid;
  grid-template-columns: 4fr 4fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 12px;
  grid-auto-rows: minmax(38px, auto);
  grid-template-areas:
    "company_id . . . . ."
    "titleInp urlInp typeInp typeInp typeInp typeInp"
    "indexInp . . . . ."". .  . trash update update";

  .company_id {
    grid-area: company_id;
  }

  .indexInp {
    grid-area: indexInp;
    max-width: 100px;
    input.rc-input-number-input {
      color: #676767;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
    }
  }

  .titleInp {
    grid-area: titleInp;
  }

  .urlInp {
    grid-area: urlInp;
  }

  .typeSel {
    grid-area: typeInp;
  }

  .trashInp {
    grid-area: trash;
    align-self: center;
  }

  .updateInp {
    grid-area: update;
  }

  select {
    width: 100%;
  }

  input[type="text"],
  input[type="number"] {
    width: 100%;
  }

}