.app.analytics {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    padding-bottom: 100px;

    .search-filter {
      display: grid;
    }

    .rc-collapse {
      margin-top: 28px;
    }

    > .analytics-controls {
      margin: 16px 0 40px 0;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;

      #filter-by-company-id{
        width: 240px;
        margin-right: 16px;
      }

      .DateRangePicker > * {
        font-family: 'Open sans', sans-serif !important;
      }
    }

    .sk-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    > h3 {
      font-size: 14px;
      margin: 32px 0 0 0;
      color: #00A9F4;
      padding: 0;
    }

    > .ReactTable {
      margin-top: 16px;
      text-align: center !important;

      .rt-thead.-headerGroups {
        display: none;
      }
    }


    > .analytics-graphs {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

    }

  }
}
