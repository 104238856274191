.app.add-patient {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    @import "../modules/btn";
    @import "../modules/form";

    .content {
      border: 1px solid #C5C5C6;
      border-radius: 5px;
      padding: 0 20px 20px 20px;
      position: relative;

      h3 {
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        padding: 0 0 20px 0;
        color: #00A9F4;
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        appearance: none;
        border: none;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px;
        }
      }

      form.add-patient-form {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        grid-row-gap: 30px;
        grid-column-gap: 12px;
        grid-auto-rows: minmax(38px, auto);
        grid-template-areas: "company_id company_id . . ."
          "patient_id patient_id password password password"
          ". . . reset add";

        .company_id {
          grid-area: company_id;
        }

        .patient_id {
          grid-area: patient_id;
        }

        .password {
          grid-area: password;
        }

        .reset {
          grid-area: reset;
          align-self: center;
        }

        .add {
          grid-area: add;
        }

        fieldset {
          margin: 0;
          padding: 0;
          border: none;
        }

        .form-help {
          font-size: 14px;
          color: #C5C5C6;
          font-family: 'Open Sans', sans-serif;
          padding: 0;
          margin: 5px 0 0 0;
        }

        input[type="text"] {
          border: 1px solid #C5C5C6;
          box-sizing: border-box;
          width: 100%;
          border-radius: 5px;
          color: #676767;
          font-family: 'Open Sans', sans-serif;
          font-size: 18px;
          padding: 5px;
          line-height: 1.5;
          position: relative;
        }

        input[type="image"] {
          width: 19px;
          height: 24px;
          margin: 0 7px 0 9px;
          justify-self: end;
          align-self: center;
        }
      }
    }


  }
}