div.sync > button:before,
.sync:before {
  content: " ";
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='none' fill='rgb(255, 255, 255)' d='M 29.27,-0 L 28.67,-0 C 28.27,-0 27.94,0.32 27.94,0.73 L 27.94,7.42 C 25.34,2.98 20.52,-0 15,-0 6.97,-0 0.41,6.31 0.02,14.24 -0,14.66 0.33,15 0.74,15 L 1.35,15 C 1.73,15 2.05,14.7 2.07,14.32 2.43,7.51 8.05,2.06 15,2.06 20.01,2.06 24.36,4.9 26.51,9.07 L 19.6,9.07 C 19.2,9.07 18.87,9.4 18.87,9.8 L 18.87,10.4 C 18.87,10.8 19.2,11.13 19.6,11.13 L 29.27,11.13 C 29.68,11.13 30,10.8 30,10.4 L 30,0.73 C 30,0.32 29.68,-0 29.27,-0 Z M 29.26,15 L 28.65,15 C 28.27,15 27.95,15.3 27.93,15.68 27.57,22.51 21.93,27.94 15,27.94 10,27.94 5.64,25.1 3.49,20.93 L 10.4,20.93 C 10.8,20.93 11.13,20.6 11.13,20.2 L 11.13,19.6 C 11.13,19.2 10.8,18.87 10.4,18.87 L 0.73,18.87 C 0.32,18.87 0,19.2 0,19.6 L 0,29.27 C 0,29.68 0.32,30 0.73,30 L 1.33,30 C 1.73,30 2.06,29.68 2.06,29.27 L 2.06,22.58 C 4.66,27.02 9.48,30 15,30 23.03,30 29.59,23.69 29.98,15.76 30,15.34 29.67,15 29.26,15 Z M 29.26,15' /%3E%3C/svg%3E%0A");
  animation: rotate 1.2s linear infinite;
  background-size: 22px;
  display: block;
  width: 22px;
  height: 22px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}