.show-errors {
  position: fixed;
  top: 5px;
  right: 15px;
  max-width: 250px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  .error {
    width: 100%;
    position: relative;

    p {
      font-family: 'Open Sans', sans-serif;
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      font-size: 16px;
      padding: 10px 40px 10px 10px;
      margin: 0 0 5px 0;
      border-radius: 5px;
    }

    a {
      position: absolute;
      top: 2px;
      right: 0;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
      }
    }

    button{
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      cursor: pointer;
      img{
        width: 10px;
        height: 10px;
      }
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation: slideInRight 0.3s ease-out;
}