.DateRangePickerInput > * {
  font-family: 'Open sans', sans-serif !important;
}

.DateInput_input::placeholder { color: #00b0ea; }

.DateInput_input__focused {
  border-bottom: 2px solid #00A9F4;
}

.DateRangePicker_picker .CalendarDay__selected_end,
.DateRangePicker_picker .CalendarDay__selected_start {
  background: #00A9F4;
  border: 1px double #00A9F4;
}

.DateRangePicker_picker .CalendarDay__selected_span{
  background: rgba(0, 169, 244, .7);
  border: 1px double #00A9F4;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #00A9F4;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #0086c1;
}