.checkbox {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  margin: 0;
  padding: 0;
  width: 120px;

  span {
    display: block;
    font-size: 16px;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    color: #A7A8AA;
    position: relative;
    transition: all 0.2s ease-in;
    padding: 6px 0;

    &::before {
      content: '';
      appearance: none;
      background-color: transparent;
      border: 1px solid #C7BFDB;
      box-shadow: 0 1px 2px rgba(255, 255, 255, 0.05), inset 0px -15px 10px -12px rgba(255, 255, 255, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin: -4px 10px 0 20px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + span:after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 27px;
      width: 6px;
      height: 14px;
      border: solid #ba006e;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}