.edit-help {

  @import "../modules/btn";
  @import "../modules/form";

  padding: 12px 0 24px 0;
  display: grid;
  grid-template-columns: 4fr 4fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 12px;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "company_id . . . . ."
    "title type checkbox checkbox . ."
    "linkTitle linkURL  . . . ."
    "body body body body body body"
    "index . . . . ."
    ". . . trash update update";

  .company_id {
    grid-area: company_id;
  }

  .title {
    grid-area: title;
  }

  .index {
    grid-area: index;
    max-width: 100px;
    input.rc-input-number-input {
      color: #676767;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
    }
  }

  .type {
    grid-area: type;
  }

  .linkTitle {
    grid-area: linkTitle;
  }

  .checkbox {
    grid-area: checkbox;
  }

  .linkURL {
    grid-area: linkURL;
  }

  .body {
    grid-area: body;
  }

  .trash {
    grid-area: trash;
    align-self: center;
    justify-self: end;
  }

  .update {
    grid-area: update;
    align-self: center;
  }

  select {
    border: 1px solid #C5C5C6;
    border-radius: 5px;
    color: #676767;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding: 5px;
    line-height: 1.5;
    background: #fff url("../../svg/chevron.svg") no-repeat right .75rem center;
    background-size: 23px 15px;
    appearance: none;
    width: 100%;
  }

  input[type="text"],
  input[type="number"] {
    border: 1px solid #C5C5C6;
    border-radius: 5px;
    color: #676767;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding: 5px;
    line-height: 1.5;
    width: 100%;
  }

  textarea {
    border: 1px solid #C5C5C6;
    border-radius: 5px;
    color: #676767;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding: 5px;
    line-height: 1.5;
    height: 128px;
    width: 100%;
  }

  input[type="image"] {
    width: 19px;
    height: 24px;
    margin: 0 7px 0 9px;
    justify-self: end;
    align-self: center;
  }
}