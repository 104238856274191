#masthead {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  height: 70px;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #00A9F4;
    z-index: 1;
  }

  .site-branding {
    flex: 0 0 110px;
    align-self: flex-end;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      img {
        width: 85px;
        height: 85px * 31px / 71px;
      }
    }
  }

  #site-navigation {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;

    ul.nav-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      line-height: 24px;


      li {
        margin: 0 10px;
        padding: 5px 15px;
        display: inline-block;
        position: relative;
        z-index: 0;

        &.active {
          border-radius: 15px 15px 0 0;
          border-top: 2px solid #00A9F4;
          border-right: 2px solid #00A9F4;
          border-left: 2px solid #00A9F4;
          color: #00A9F4;
          z-index: 2;
          border-bottom: 2px solid #FFF;

          a {
            color: #00A9F4;
          }

          &:before,
          &:after {
            position: absolute;
            bottom: -2px;
            width: 8px;
            height: 8px;
            content: " ";
          }

          &:before {
            left: -10px;
          }

          &:after {
            right: -10px;
          }

          &:after,
          &:before {
            border: 2px solid #00A9F4;
          }

          &:before {
            border-bottom-right-radius: 15px;
            border-width: 0 2.5px 2px 0;
          }

          &:after {
            border-bottom-left-radius: 15px;
            border-width: 0 0 2px 2px;
          }

          &:before {
            box-shadow: 2.1px 2.1px 0 #FFF;
          }

          &:after {
            box-shadow: -2.1px 2.1px 0 #FFF;
          }

        }

        a {
          font-family: 'Open Sans', sans-serif;
          text-decoration: none;
          color: #A7A8AA;
        }
      }
    }
  }

  .logout {
    border: none;

    img {}
  }
}