.quick-search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .quick-search-container {
      position: relative;

      input[type="text"] {
        border: 1px solid #C5C5C6;
        border-radius: 5px;
        color: #676767;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        padding: 4px 4px 4px 34px;
        line-height: 1.5;
        position: relative;
      }

      &:before {
        content: " ";
        position: absolute;
        top: 9px;
        left: 7px;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"  xml:space="preserve"><path stroke="none" fill="rgb(167, 168, 170)" d="M 19.86,18.81 L 14.82,13.77 C 14.73,13.68 14.62,13.64 14.49,13.64 L 14.09,13.64 C 15.43,12.19 16.25,10.25 16.25,8.12 16.25,3.64 12.61,-0 8.12,-0 3.64,-0 0,3.64 0,8.12 0,12.61 3.64,16.25 8.12,16.25 10.25,16.25 12.19,15.43 13.64,14.09 L 13.64,14.49 C 13.64,14.62 13.69,14.73 13.77,14.82 L 18.81,19.86 C 19,20.05 19.29,20.05 19.48,19.86 L 19.86,19.48 C 20.05,19.29 20.05,19 19.86,18.81 Z M 8.12,15 C 4.32,15 1.25,11.93 1.25,8.12 1.25,4.32 4.32,1.25 8.12,1.25 11.93,1.25 15,4.32 15,8.12 15,11.93 11.93,15 8.12,15 Z M 8.12,15" /></svg>');
        background-repeat: no-repeat;
        display: block;
        width: 25px;
        height: 25px;
        z-index: 10;
      }
    }


    #filter-by-company-id {
      width: 240px;
      margin: 0 16px;
    }

    .close-icon {
      position: relative;
      background-color: transparent;
      vertical-align: middle;
      outline: 0;
      padding: 0;
      margin: 0;
      border: 0;

      &:after {
        content: " ";
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="19" height="19"><path stroke="none" fill="%23C5C5C6" d="M 9.5,-0 C 4.25,-0 0,4.25 0,9.5 0,14.75 4.25,19 9.5,19 14.75,19 19,14.75 19,9.5 19,4.25 14.75,-0 9.5,-0 Z M 9.5,17.77 C 4.95,17.77 1.23,14.09 1.23,9.5 1.23,4.95 4.91,1.23 9.5,1.23 14.05,1.23 17.77,4.91 17.77,9.5 17.77,14.05 14.09,17.77 9.5,17.77 Z M 13.13,6.85 L 10.48,9.5 13.13,12.15 C 13.31,12.33 13.31,12.63 13.13,12.81 L 12.81,13.13 C 12.63,13.31 12.33,13.31 12.15,13.13 L 9.5,10.48 6.85,13.13 C 6.67,13.31 6.37,13.31 6.19,13.13 L 5.87,12.81 C 5.69,12.63 5.69,12.33 5.87,12.15 L 8.52,9.5 5.87,6.85 C 5.69,6.67 5.69,6.37 5.87,6.19 L 6.19,5.87 C 6.37,5.69 6.67,5.69 6.85,5.87 L 9.5,8.52 12.15,5.87 C 12.33,5.69 12.63,5.69 12.81,5.87 L 13.13,6.19 C 13.31,6.37 13.31,6.67 13.13,6.85 Z M 13.13,6.85" /></svg>');
        background-repeat: no-repeat;
        display: block;
        width: 19px;
        height: 19px;
        position: absolute;
        z-index: 9999999999;
        right: 8px;
        top: -10px;
        cursor: pointer;
      }
    }

  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #A7A8AA;
  }
}