.Calls {
  padding: 0 0 50px 0;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;

  >h1 {
    font-size: 23px;
    font-weight: normal;
    margin: 0;
    padding: 9px 0 4px 0;
    line-height: 1.3;
  }

  >.call-controls {
    margin: 16px 0 40px 0;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    align-items: center;

    #filter-by-company-id {
      width: 240px;
      margin: 0 16px 0 0;
    }

    .CsvBtn {
      margin-left: auto;
    }

  }

  >.kpi-indicators {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .sk-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  >.ReactTable {
    margin-top: 34px;

    .rt-thead.-headerGroups {
      display: none;
    }
  }

}