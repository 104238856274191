.app.lost {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    p {
      font-size: 15px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      color: #A7A8AA;
    }
  }
}