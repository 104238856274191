.app.company-admins {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    @import "../modules/btn";

    .rc-collapse {
      margin-top: 28px;
    }

  }
}