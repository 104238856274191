.LogoDropzone {
  margin: 10px 0 20px 0;
  padding: 50px;
  text-align: center;
  border: 5px dashed #B4B9BE;

  p {
    font-size: 20px;
    color: #676767;
    margin: 0;
  }

  p+p {
    font-size: 14px;
    font-style: italic;
    margin: 8px 0;
  }

  p+p+p {
    font-size: 14px;
    margin: 16px 0;
  }

  button {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #00A9F4;
    color: #00A9F4;
    cursor: pointer;
    transition: all 0.2s ease-in;

    &:hover {
      background-color: #f8f8f8;
    }
  }
}