#login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  align-content: center;

  .inner-content {
    width: 303px;

    flex: 0 0 303px;

    header {
      margin-bottom: 100px;
    }

    form {
      display: flex;
      flex-flow: column nowrap;

      input[type="text"],
      input[type="password"] {
        display: block;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        border: 1px solid #ced4da;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: .25rem;
        margin-bottom: 17px;
        box-sizing: border-box;
        position: relative;
        font-family: 'Open Sans', sans-serif;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

        &:focus {
          color: #495057;
          background-color: #fff;
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        }

        &:read-only,
        &:disabled {
          background-color: #e9ecef !important;
          cursor: not-allowed !important;
        }

      }

      .close-icon {
        position: relative;
        background-color: transparent;
        vertical-align: middle;
        outline: 0;
        padding: 0;
        margin: 0;
        border: 0;

        &:after {
          content: " ";
          background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path stroke="none" fill="#C5C5C6" d="M 9.5,-0 C 4.25,-0 0,4.25 0,9.5 0,14.75 4.25,19 9.5,19 14.75,19 19,14.75 19,9.5 19,4.25 14.75,-0 9.5,-0 Z M 9.5,17.77 C 4.95,17.77 1.23,14.09 1.23,9.5 1.23,4.95 4.91,1.23 9.5,1.23 14.05,1.23 17.77,4.91 17.77,9.5 17.77,14.05 14.09,17.77 9.5,17.77 Z M 13.13,6.85 L 10.48,9.5 13.13,12.15 C 13.31,12.33 13.31,12.63 13.13,12.81 L 12.81,13.13 C 12.63,13.31 12.33,13.31 12.15,13.13 L 9.5,10.48 6.85,13.13 C 6.67,13.31 6.37,13.31 6.19,13.13 L 5.87,12.81 C 5.69,12.63 5.69,12.33 5.87,12.15 L 8.52,9.5 5.87,6.85 C 5.69,6.67 5.69,6.37 5.87,6.19 L 6.19,5.87 C 6.37,5.69 6.67,5.69 6.85,5.87 L 9.5,8.52 12.15,5.87 C 12.33,5.69 12.63,5.69 12.81,5.87 L 13.13,6.19 C 13.31,6.37 13.31,6.67 13.13,6.85 Z M 13.13,6.85" /></svg>');
          background-repeat: no-repeat;
          display: block;
          width: 19px;
          height: 19px;
          position: absolute;
          z-index: 1;
          right: 8px;
          top: -45px;
          cursor: pointer;
        }
      }

      button[type="submit"] {
        position: relative;
        width: 100%;
        height: 38px;
        background-color: #00A9F4;
        color: white;
        padding: 6px 12px;
        border: none;
        font-size: 24px;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        margin-bottom: 17px;
        font-family: 'Open Sans', sans-serif;
        transition: all 0.3s ease-in;
        cursor: pointer;

        &:hover {
          background-color: darken(#00A9F4, 5%);
        }

        &:focus {
          border-color: #80bdff;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
        }
      }

      a {
        display: block;
        text-align: center;
        color: white;
        text-decoration: none;
        font-family: 'Open Sans', sans-serif;
      }

    }

  }

}

@import "../modules/sync";