.app.add-resource {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    @import "../modules/btn";
    @import "../modules/form";

    .content {
      border: 1px solid #C5C5C6;
      border-radius: 5px;
      padding: 0 20px 20px 20px;
      position: relative;

      h3 {
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        padding: 0 0 20px 0;
        color: #00A9F4;
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        appearance: none;
        border: none;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px;
        }
      }

      form.add-resource-form {
        display: grid;
        grid-template-columns: 4fr 4fr 2fr 2fr;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
        grid-auto-rows: minmax(38px, auto);
        grid-template-areas:
          "company_id . . ."
          "title url type type"
          "index . . ."
          ". . reset add";

        .company_id {
          grid-area: company_id;
        }

        .index {
          grid-area: index;
          max-width: 100px;
          input.rc-input-number-input {
            color: #676767;
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
          }
        }

        .title {
          grid-area: title;
        }

        .url {
          grid-area: url;
        }

        .type {
          grid-area: type;
        }

        .reset {
          grid-area: reset;
          align-self: center;
        }

        .add {
          grid-area: add;
        }

        input[type="text"],
        input[type="number"] {
          width: 100%;
        }

        select {
          width: 100%;
        }

        .form-help {
          font-size: 14px;
          color: #C5C5C6;
          font-family: 'Open Sans', sans-serif;
          padding: 0;
          margin: 5px 0 0 0;
        }

      }
    }
  }
}