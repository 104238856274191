.feedbackGraphContainer {
  width: 50%;
  height: 50%;
  background: white;

  h3 {
    font-family: 'Open sans', sans-serif;
    font-size: 14px;
    margin: 32px 0 16px 0;
    padding: 0;
    color: #00A9F4;

    span {
      margin: 0 0 0 32px;
      font-weight: 400;
      color: #A7A8AA;
    }
  }
}

@media only screen and (max-width: 600px) {
  .feedbackGraphContainer {
    width: 100%;
  }
}
