.app.add-help {
  .inner-content {
    max-width: 90%;
    margin: 0 auto;

    @import "../modules/btn";
    @import "../modules/form";

    .content {
      border: 1px solid #C5C5C6;
      border-radius: 5px;
      padding: 0 20px 20px 20px;
      position: relative;

      h3 {
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        font-weight: normal;
        padding: 0 0 20px 0;
        color: #00A9F4;
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        appearance: none;
        border: none;
        cursor: pointer;

        img {
          width: 17px;
          height: 17px;
        }
      }

      form.add-help-form {
        display: grid;
        grid-template-columns: 4fr 4fr 1fr 1fr 1fr 1fr;
        grid-row-gap: 12px;
        grid-column-gap: 12px;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
          "company_id . . . . ."
          "title type checkbox checkbox . ."
          "linkTitle linkURL  . . . ."
          "body body body body body body"
          "index . . . . ."
          ". . . reset add add";

        .index {
          grid-area: index;
          max-width: 100px;
          input.rc-input-number-input {
            color: #676767;
            font-family: 'Open Sans', sans-serif;
            font-size: 18px;
          }
        }

        .company_id {
          grid-area: company_id;
        }

        .title {
          grid-area: title;
        }

        .type {
          grid-area: type;
        }

        .linkTitle {
          grid-area: linkTitle;
        }

        .checkbox {
          grid-area: checkbox;
        }

        .linkURL {
          grid-area: linkURL;
        }

        .body {
          grid-area: body;
        }

        .reset {
          grid-area: reset;
          align-self: center;
          justify-self: end;
        }

        .error {
          border-color: #dc3545;
          box-shadow: 0 0 4px 0 #dc3545;
        }

        .add {
          grid-area: add;
        }

        fieldset {
          margin: 0;
          padding: 0;
          border: none;
        }

        .close-icon {
          position: relative;
          background-color: transparent;
          vertical-align: middle;
          outline: 0;
          padding: 0;
          margin: 0;
          border: 0;

          &:after {
            content: " ";
            background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path stroke="none" fill="#C5C5C6" d="M 9.5,-0 C 4.25,-0 0,4.25 0,9.5 0,14.75 4.25,19 9.5,19 14.75,19 19,14.75 19,9.5 19,4.25 14.75,-0 9.5,-0 Z M 9.5,17.77 C 4.95,17.77 1.23,14.09 1.23,9.5 1.23,4.95 4.91,1.23 9.5,1.23 14.05,1.23 17.77,4.91 17.77,9.5 17.77,14.05 14.09,17.77 9.5,17.77 Z M 13.13,6.85 L 10.48,9.5 13.13,12.15 C 13.31,12.33 13.31,12.63 13.13,12.81 L 12.81,13.13 C 12.63,13.31 12.33,13.31 12.15,13.13 L 9.5,10.48 6.85,13.13 C 6.67,13.31 6.37,13.31 6.19,13.13 L 5.87,12.81 C 5.69,12.63 5.69,12.33 5.87,12.15 L 8.52,9.5 5.87,6.85 C 5.69,6.67 5.69,6.37 5.87,6.19 L 6.19,5.87 C 6.37,5.69 6.67,5.69 6.85,5.87 L 9.5,8.52 12.15,5.87 C 12.33,5.69 12.63,5.69 12.81,5.87 L 13.13,6.19 C 13.31,6.37 13.31,6.67 13.13,6.85 Z M 13.13,6.85" /></svg>');
            background-repeat: no-repeat;
            display: block;
            width: 19px;
            height: 19px;
            position: absolute;
            z-index: 1;
            right: 8px;
            top: -11px;
            cursor: pointer;
          }
        }

        .form-help {
          font-size: 14px;
          color: #C5C5C6;
          font-family: 'Open Sans', sans-serif;
          padding: 0;
          margin: 5px 0 0 0;
        }

        select {
          width: 100%;
        }

        input[type="text"],
        input[type="number"] {

          width: 100%;
        }

        textarea {
          border: 1px solid #C5C5C6;
          border-radius: 5px;
          color: #676767;
          font-family: 'Open Sans', sans-serif;
          font-size: 18px;
          padding: 5px;
          line-height: 1.5;
          height: 128px;
          width: 100%;
        }

        input[type="image"] {
          width: 19px;
          height: 24px;
          margin: 0 7px 0 9px;
          justify-self: end;
          align-self: center;
        }

        input[type="submit"] {
          align-self: center;
        }
      }
    }


  }
}