.btn {
  background-color: #00A9F4;
  color: white;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: darken(#00A9F4, 10%);
  }


}