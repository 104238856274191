.quick-search {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .quick-search-container {
      position: relative;

      input[type="text"] {
        border: 1px solid #C5C5C6;
        border-radius: 5px;
        color: #676767;
        font-family: 'Open Sans', sans-serif;
        font-size: 18px;
        padding: 4px 4px 4px 34px;
        line-height: 1.5;
        position: relative;
      }

      &:before {
        content: " ";
        position: absolute;
        top: 9px;
        left: 7px;
        background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"  xml:space="preserve"><path stroke="none" fill="rgb(167, 168, 170)" d="M 19.86,18.81 L 14.82,13.77 C 14.73,13.68 14.62,13.64 14.49,13.64 L 14.09,13.64 C 15.43,12.19 16.25,10.25 16.25,8.12 16.25,3.64 12.61,-0 8.12,-0 3.64,-0 0,3.64 0,8.12 0,12.61 3.64,16.25 8.12,16.25 10.25,16.25 12.19,15.43 13.64,14.09 L 13.64,14.49 C 13.64,14.62 13.69,14.73 13.77,14.82 L 18.81,19.86 C 19,20.05 19.29,20.05 19.48,19.86 L 19.86,19.48 C 20.05,19.29 20.05,19 19.86,18.81 Z M 8.12,15 C 4.32,15 1.25,11.93 1.25,8.12 1.25,4.32 4.32,1.25 8.12,1.25 11.93,1.25 15,4.32 15,8.12 15,11.93 11.93,15 8.12,15 Z M 8.12,15" /></svg>');
        background-repeat: no-repeat;
        display: block;
        width: 25px;
        height: 25px;
        z-index: 10;
      }
    }

  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    color: #A7A8AA;
    margin: 0 0 0 16px;
  }
}