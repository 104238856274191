.edit-patient-form {

  @import "../modules/btn";
  @import "../modules/form";

  padding: 12px 0 24px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 24px;
  grid-column-gap: 12px;
  grid-auto-rows: minmax(38px, auto);
  grid-template-areas:
    "company_id company_id company_id company_id company_id company_id . . . . . ."
    "patient_id patient_id patient_id patient_id patient_id patient_id password password password password password password"
    ". . . . . . . . . trash update update";

  .company_id {
    grid-area: company_id;
  }

  .patient_id {
    grid-area: patient_id;
  }

  .password {
    grid-area: password;
  }

  .trash {
    grid-area: trash;
    align-self: center;
  }

  .update {
    grid-area: update;
  }

  input[type="text"] {
    border: 1px solid #C5C5C6;
    border-radius: 5px;
    color: #676767;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    padding: 5px;
    line-height: 1.5;
    width: 100%;
  }

  input[type="image"] {
    width: 19px;
    height: 24px;
    margin: 0 7px 0 9px;
    justify-self: end;
    align-self: center;
  }
}