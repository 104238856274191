.entry-header {
  text-align: center;

  .entry-title {
    font-size: 30px;
    color: #A7A8AA;
    font-family: 'Open Sans', sans-serif;
    margin: 20px 0 40px 0;
    padding: 0;
  }
}