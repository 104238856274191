.rc-collapse-anim-active {
  transition: height 0.2s ease-out;
}


.rc-collapse>.rc-collapse-item {
  border: 1px solid #C5C5C6;
  border-radius: 5px;
  margin: 0 0 9px 0;
}

.rc-collapse>.rc-collapse-item>.rc-collapse-header {
  height: 38px;
  line-height: 38px;
  text-indent: 16px;
  cursor: pointer;
  font-size: 22px;
  color: #00A9F4;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  >span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
    padding-right: 5px;
  }

}

.rc-collapse>.rc-collapse-item>.rc-collapse-header .arrow {
  flex: 0 0 20px;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background-image: url(../svg/plus.svg);
  margin-right: 10px;
  background-repeat: no-repeat;
}

.rc-collapse>.rc-collapse-item-disabled>.rc-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}

.rc-collapse-content {
  overflow: hidden;
  color: #666666;
  padding: 0 16px;
  background-color: #fff;
}

.rc-collapse-content {
  margin: 1px;
}

.rc-collapse-content>.rc-collapse-content-box {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rc-collapse-content-inactive {
  display: none;
}

.rc-collapse-item:last-child>.rc-collapse-content {
  border-radius: 0 0 3px 3px;
}

.rc-collapse>.rc-collapse-item-active>.rc-collapse-header .arrow {
  background-image: url(../svg/minus.svg);
}